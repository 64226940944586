.modal-content-one {
  display: none;

  .modal--one & {
    display: block;
  }
}

.modal-content-two {
  display: none;

  .modal--two & {
    display: block;
  }
}

.modal-content-three {
  display: none;

  .modal--three & {
    display: block;
  }
}

.modal {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-modal);
  width: 100vw;
  min-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &--out {
    pointer-events: none;
  }

  &__backdrop {
    position: fixed;
    inset: 0;
    z-index: 1;
    background: hsla(200 2% 6% / 1);
    opacity: 0;

    .modal--in & {
      opacity: .36;
      transition: opacity 400ms cubic-bezier(0, 0, 0.5, 1);
    }

    .modal--out & {
      opacity: 0;
      transition: opacity 550ms cubic-bezier(.24, 0, .71, 1.01);
    }
  }

  &__scroller {
    position: relative;
    z-index: 2;
    min-height: 100%;
    display: grid;
    place-content: center;
  }

  &__container {
    position: relative;
    z-index: 3;
    //width: 100vw;
    padding: 2rem;
  }
}

.modal-content {
  position: relative;
  width: fit-content;
  margin: auto;
  border-radius: 1px;
  opacity: 0;
  transform: translateY(-30px);
  display: flex;

  .modal--in & {
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity 525ms cubic-bezier(.61, 1, .88, 1),
      transform 525ms cubic-bezier(.61, 1, .88, 1);
  }

  .modal--out & {
    opacity: 0;
    transform: translateY(40px);
    transition:
      opacity 625ms cubic-bezier(0, 0, 0.5, 1),
      transform 675ms cubic-bezier(.22, 1, .5, .95);
  }

  .modal--two & {
    [class^="money-part-"] {
      pointer-events: none;
      position: absolute;
      inset: 0;
      z-index: 3;
      width: calc(100% + 10vmin);
      height: calc(100% + 5vmin);
      background-repeat: no-repeat;
    }

    .money-part-left {
      margin: -8vmin 0 0 -10vmin;
      background-size: clamp(80px, 20vw, 200px) auto;
      background-position: left top;
    }

    .money-part-right {
      margin: -8vmin 0 0 0;
      background-size: clamp(120px, 20vw, 260px) auto;
      background-position: right top;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    inset: 0 0 auto auto;
    z-index: 1;
    display: grid;
    place-content: center;
    width: 50px;
    height: 50px;
    transform: translate(0, 45px);

    .svg-icon {
      width: 32px;
      height: 32px;
    }
  }
}
